import React from 'react';
import './footer.sass';
import { ADDRESS, PHONE } from '../../data/address-phone-hours';
const facebookLogo = require('../../assets/facebook.svg');
const instagramLogo = require('../../assets/instagram.svg');

const Footer = () => (
  <div className="footer">
    <p className="r">In Loving Memory of our beloved Richard. We love and miss you always.</p>
    <div className="contact-information">
      <p className="footer-address street">{ADDRESS.streetAddress}</p>
      <p className="footer-address city">{ADDRESS.cityState}</p>
      <p className="footer-address footer-phone">
        &#40;{PHONE.phoneAreaCode}&#41; {PHONE.phonePrefixAndList}
      </p>
    </div>
    <div className="social-media-links">
      <a
        className="links facebook"
        href="https://www.facebook.com/pages/category/Health-Spa/Rino-Nail-Bar-345291496320023/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={facebookLogo}
          className="logo facebook-logo "
          alt="facebook-logo"
        />
      </a>
      <a
        className="links instagram"
        href="https://www.instagram.com/rinonailbar/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={instagramLogo}
          className="logo instagram-logo "
          alt="instagram-logo"
        />
      </a>
    </div>
  </div>
);

export default Footer;
