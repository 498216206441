import React from 'react';
import Slider from 'react-slick';
import ServiceBuild from '../service-build';
import './services-carousel.sass';

const style = {
  dots: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true
};

const ServiceCarousel = ({ colorScheme }) => {
  return (
    <div className={`service-carousel ${colorScheme}`}>
      <Slider {...style}>
        <div>
          <h3 className="title">SERVICES</h3>
          <ServiceBuild groupNumber={1} />
        </div>
        <div>
          <h3 className="title">SERVICES</h3>
          <ServiceBuild groupNumber={0} />
        </div>
        <div>
          <h3 className="title">SERVICES</h3>
          <ServiceBuild groupNumber={2} />
        </div>
        <div>
          <h3 className="title">SERVICES</h3>
          <ServiceBuild groupNumber={4} />
        </div>
        <div>
          <h3 className="title">SERVICES</h3>
          <ServiceBuild groupNumber={3} />
        </div>
      </Slider>
    </div>
  );
};

export default ServiceCarousel;
