// This is the interface you will want to use with this dataset:

// group: [ {Note: Each group can be thought of as a column, so if you need a new column, add a new group. Otherwise, update an existing group}
//   serviceTitle: {title},
//   serviceOptions: [
//     {
//       subTitle: {subTitle || null},
//       options: [
//         {
//           name: {name},
//           cost: {price},
//           addOn: {addOnname || null},
//           additionalCost: {additionalCost || null}
//         }
//       ]
//     }
//   ]
// ]

const SERVICES = [
  {
    group: [
      {
        serviceTitle: 'DIPPING POWDER',
        serviceOptions: [
          {
            subTitle: null,
            options: [
              {
                name: 'Full Set Overlay',
                cost: 65,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Full Set w/ Extension',
                cost: 75,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Pink and White',
                cost: 75,
                addOn: null,
                additionalCost: null
              }
            ]
          }
        ]
      },
      {
        serviceTitle: 'MANICURES',
        serviceOptions: [
          {
            subTitle: null,
            options: [
              {
                name: 'Organic Bamboo Manicure',
                cost: 50,
                addOn: 'w/ Gel',
                additionalCost: '+15'
              },
              {
                name: 'Rehydration Manicure',
                cost: 40,
                addOn: 'w/ Gel',
                additionalCost: '+15'
              },
              {
                name: 'Refresh Manicure',
                cost: 30,
                addOn: 'w/ Gel',
                additionalCost: '+15'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    group: [
      {
        serviceTitle: 'FASHION NAILS',
        serviceOptions: [
          {
            subTitle: 'Solar/Gel Nails',
            options: [
              {
                name: 'Full Set Overlay/Extension',
                cost: 60,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Full Set w/ Gel Polish',
                cost: 75,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Pink and White Solar',
                cost: 90,
                addOn: null,
                additionalCost: null
              }
            ]
          },
          {
            subTitle: 'Fashion Nail/Rebase',
            options: [
              {
                name: 'Rebase Overlay/Extension',
                cost: 60,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Rebase w/ Gel Polish',
                cost: 65,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Rebase Pink and White Solar',
                cost: 85,
                addOn: null,
                additionalCost: null
              }
            ]
          },
          {
            subTitle: 'Gel X',
            options: [
              {
                name: 'Extra Short Full Set',
                cost: 75,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Short Full Set',
                cost: 80,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Medium Full Set',
                cost: 85,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Long Full Set',
                cost: 90,
                addOn: null,
                additionalCost: null
              }
            ]
          }
        ]
      }
    ]
  },
  {
    group: [
      {
        serviceTitle: 'PEDICURES',
        serviceOptions: [
          {
            subTitle: null,
            options: [
              {
                name: 'Organic Bamboo Pedicure',
                cost: 70,
                addOn: 'w/ Gel',
                additionalCost: '+15'
              },
              {
                name: 'Rehydration Pedicure',
                cost: 55,
                addOn: 'w/ Gel',
                additionalCost: '+15'
              },
              {
                name: 'Refresh Pedicure',
                cost: 45,
                addOn: 'w/ Gel',
                additionalCost: '+15'
              }
            ]
          },
          {
            subTitle: 'Additional Options',
            options: [
              {
                name: 'Nail Art',
                cost: '+10',
                addOn: null,
                additionalCost: null
              },
              {
                name: 'French Tip',
                cost: '+15',
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Callus Remover',
                cost: '+10',
                addOn: null,
                additionalCost: null
              }
            ]
          }
        ]
      }
    ]
  },
  {
    group: [
      {
        serviceTitle: 'WAXING',
        serviceOptions: [
          {
            subTitle: null,
            options: [
              {
                name: 'Brow Shaping (wax, trim, tweeze)',
                cost: 20,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Lip or Chin',
                cost: 15,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Sideburns',
                cost: 20,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Underarms',
                cost: 30,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Half Arm',
                cost: 45,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Full Arm',
                cost: 60,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Half Leg',
                cost: 60,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Full Leg',
                cost: 85,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Full Face',
                cost: 65,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Bikini',
                cost: 65,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Brazilian',
                cost: 85,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Chest',
                cost: '50+',
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Back',
                cost: '65+',
                addOn: null,
                additionalCost: null
              },
            ]
          }
        ]
      }
    ]
  },
  {
    group: [
      {
        serviceTitle: 'EYELASH EXTENSIONS',
        serviceOptions: [
          {
            subTitle: 'Classic Set',
            options: [
              {
                name: 'Full Set',
                cost: 200,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Refill 1 Week',
                cost: 65,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Refill 2 Weeks',
                cost: 150,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Refill 3 Weeks',
                cost: 180,
                addOn: null,
                additionalCost: null
              }
            ]
          },
          {
            subTitle: 'Volume Set',
            options: [
              {
                name: 'Full Set',
                cost: 300,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Volume Refill 1 Week',
                cost: 100,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Volume Refill 2 Weeks',
                cost: 150,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Volume Refill 3 Weeks',
                cost: 200,
                addOn: null,
                additionalCost: null
              },
            ]
          },
          {
            subTitle: 'Additional Options',
            options: [
              {
                name: 'Lash Lift',
                cost: 65,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Lash Tint',
                cost: 45,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Lash Tint and Lift',
                cost: 100,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Lash Removal',
                cost: 50,
                addOn: null,
                additionalCost: null
              },
              {
                name: 'Brow Tint',
                cost: 55,
                addOn: null,
                additionalCost: null
              },
            ]
          }
        ]
      }
    ]
  },
];

export default SERVICES;
