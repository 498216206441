const ADDRESS = {
  streetTitle: 'LARIMER ST.',
  streetAddress: '2931 Larimer St.',
  cityState: 'Denver, CO 80205'
};

const PHONE = {
  phoneAreaCode: '720',
  phonePrefixAndList: '999-6379'
};

const HOURS = {
  weekDayHours: '10AM - 7PM',
  saturdayHours: '10AM - 6PM',
  sundayHours: '11AM - 5PM'
};

export { ADDRESS, PHONE, HOURS };
