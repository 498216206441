import React from "react";
import { NavLink } from "react-router-dom";
import "./top-nav-panel.sass";

const TopNavPanel = props => {
  return (
    <div className="top-nav-panel">
      {/* <span className="home">
        <NavLink
          className="top-nav-link home"
          to="/"
          onClick={props.handleNavLinkClick}
        >
          Home
        </NavLink>
      </span> */}
      <span>
        <NavLink
          className="top-nav-link"
          to="/team"
          onClick={props.handleNavLinkClick}
        >
          Team
        </NavLink>
      </span>
      <span>
        <NavLink
          className="top-nav-link"
          to="/services"
          onClick={props.handleNavLinkClick}
        >
          Services
        </NavLink>
      </span>
      <span>
        <NavLink
          className="top-nav-link"
          to="/location"
          onClick={props.handleNavLinkClick}
        >
          Location
        </NavLink>
      </span>
      <span>
        <a
          className="top-nav-link"
          href="https://www.facebook.com/foryourmani/"
        >
          Book
        </a>
      </span>
    </div>
  );
};

export default TopNavPanel;
