import galleryOne from '../assets/gallery-1.jpg'
import galleryTwo from '../assets/gallery-2.jpg'
import galleryThree from '../assets/gallery-3.jpg'
import galleryFour from '../assets/gallery-4.jpg'
import galleryFive from '../assets/gallery-5.jpg'
import gallerySix from '../assets/gallery-6.jpg'
import gallerySeven from '../assets/gallery-7.jpg'
import galleryEight from '../assets/gallery-8.jpg'

const INTERIOR_PICS = {

  smallPics: [
    {
      item: '1',
      url: galleryOne,
      alt: 'chairs'
    },
    {
      item: '2',
      url: galleryTwo,
      alt: 'desk'
    },
    {
      item: '3',
      url: galleryThree,
      alt: 'soaker'
    },
    {
      item: '4',
      url: galleryFour,
      alt: 'polish'
    },
    {
      item: '5',
      url: galleryFive,
      alt: 'soaker'
    },
    {
      item: '6',
      url: gallerySix,
      alt: 'soaker'
    },
    {
      item: '7',
      url: gallerySeven,
      alt: 'soaker'
    },
    {
      item: '8',
      url: galleryEight,
      alt: 'soaker'
    },
  ]
}

export default INTERIOR_PICS;
