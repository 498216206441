import React from "react";
import "./our-team.sass";
import TeamPic from "../../assets/team-hero.jpg";
import BookAppointmentButton from "../appointment-button";

const OurTeam = () => {
  return (
    <div className="team-page">
      <div className="team-pic">
        <img src={TeamPic} alt="full team"></img>
      </div>
      <h2>The Team</h2>
      <p>
	Rino Nail Bar is a locally-owned nail salon inspired by the unique culture of the RiNo Art District – defined by creativity, self-expression, and forward-thinking. Our team has over 18+ years of experience in salon care, ensuring you will enjoy stunning, long-lasting nails with zero compromise on safety or quality. We invite you to come relax and rejuvenate while we pamper you!
      </p>
      <BookAppointmentButton color={"blue"} size={"narrow"} />
    </div>
  );
};

export default OurTeam;
