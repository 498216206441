import React from 'react';
import './address-card.sass';
import { ADDRESS, PHONE, HOURS } from '../../data/address-phone-hours';

const AddressCard = () => {
  return (
    <div className="address-card">
      <h3 className="street-title">{ADDRESS.streetTitle}</h3>
      <hr />
      <p className="address">{ADDRESS.streetAddress}</p>
      <p className="address">{ADDRESS.cityState}</p>
      <p className="address phone">
        &#40;{PHONE.phoneAreaCode}&#41; {PHONE.phonePrefixAndList}
      </p>
      <h3>HOURS</h3>
      <p className="days">Mon-Fri</p>
      <p className="hours">{HOURS.weekDayHours}</p>
      <p className="days">Sat</p>
      <p className="hours">{HOURS.saturdayHours}</p>
      <p className="days">Sun</p>
      <p className="hours">{HOURS.sundayHours}</p>
    </div>
  );
};

export default AddressCard;
