import React from 'react';
import { NavLink } from 'react-router-dom';
import './side-nav-panel.sass';

const SideNavPanel = props => {
  const sidePanelOpen = props.sidePanelToggle ? 'open' : '';

  return (
    <div className={`side-nav-panel ${sidePanelOpen}`}>
      <ul className="nav-list">
        <li className="home">
          <NavLink
            className="nav-link home"
            to="/"
            onClick={props.handleNavLinkClick}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            className="nav-link"
            to="/team"
            onClick={props.handleNavLinkClick}
          >
            Team
          </NavLink>
        </li>
        <li>
          <NavLink
            className="nav-link"
            to="/services"
            onClick={props.handleNavLinkClick}
          >
            Services
          </NavLink>
        </li>
        <li>
          <NavLink
            className="nav-link"
            to="/location"
            onClick={props.handleNavLinkClick}
          >
            Location
          </NavLink>
        </li>
        <li>
          <a href="https://www.facebook.com/foryourmani/">Book</a>
        </li>
      </ul>
    </div>
  );
};

export default SideNavPanel;
