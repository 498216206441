import React from 'react';

const Book = ({ pageDisplayNone }) => {
  const addDisplayNone = pageDisplayNone ? 'none' : '';

  return (
    <div className={addDisplayNone}>
      <p>Booking Page!</p>
    </div>
  );
};

export default Book;
