import React from 'react';
import './service-build.sass';
import SERVICES from '../../data/services';

const ServiceOptionsList = props => {
  return (
    <div className="optionList">
      {props.name ? (
        <div>
          <p>{props.name}</p>
          <p className={props.addOn ? 'add-on' : null}>{props.addOn}</p>
        </div>
      ) : (
        <div>
          <p>{props.cost}</p>
          <p className={props.additionalCost ? 'add-on' : null}>
            {props.additionalCost}
          </p>
        </div>
      )}
    </div>
  );
};

const ServiceSubTitleGroup = props => {
  const { subTitle, options } = props;
  return (
    <div className="subTitleContainer">
      <h5>{subTitle}</h5>
      <div className="service-name">
        <div className="name">
          {options.map((option, index) => {
            return (
              <ServiceOptionsList
                key={index}
                addOn={option.addOn}
                name={option.name}
              />
            );
          })}
        </div>
        <div className="cost">
          {options.map((option, index) => {
            return (
              <ServiceOptionsList
                key={index}
                additionalCost={option.additionalCost}
                cost={option.cost}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const ServiceColumn = props => {
  const { serviceTitle, serviceOptions } = props.service;
  return (
    <div className="col">
      <h4>{serviceTitle}</h4>
      {serviceOptions.map((service, index) => {
        return (
          <ServiceSubTitleGroup
            key={index}
            subTitle={service.subTitle}
            options={service.options}
          />
        );
      })}
    </div>
  );
};

const ServiceBuild = ({ groupNumber }) => {
  return SERVICES[groupNumber].group.map((service, index) => {
    return <ServiceColumn key={index} service={service} />;
  });
};

export default ServiceBuild;
