import React from 'react';
import './map.sass'
import MapPic from '../../assets/rino-map.jpg';

const Map = ({ resize }) => {

  return (
    <div className={resize}>
      <img src={MapPic} alt="Map"></img>
    </div>
  )
};

export default Map;
