import React, { useState, useEffect } from 'react';
import NavBar from '../nav-bar';
import SideNavPanel from '../side-nav-panel';
import Routes from '../routes';
import { team } from '../../data';
import './app.sass';

const App = props => {
  const [sidePanelToggle, setSidePanelToggle] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const pagePath = window.location.pathname;

  window.addEventListener('resize', () => {
    setWindowWidth(window.innerWidth);
  });

  // This will allow us to create some transition effects, but then remove the page from DOM
  const handleSidePanelToggle = () => {
    if (!sidePanelToggle) {
      document.body.classList.add('side-panel-open');
    } else {
      document.body.classList.remove('side-panel-open');
    }
    setSidePanelToggle(!sidePanelToggle);
  };

  const handleNavLinkClick = () => {
    document.body.classList.remove('side-panel-open');
    setSidePanelToggle(false);
  };

  useEffect(() => {
    setTeamData(team);
  }, []);

  return (
    <div className="App">
      <NavBar
        toggleSidePanel={handleSidePanelToggle}
        sidePanelToggle={sidePanelToggle}
        windowWidth={windowWidth}
      />
      {sidePanelToggle && (
        <SideNavPanel
          handleNavLinkClick={handleNavLinkClick}
          sidePanelToggle={sidePanelToggle}
        />
      )}
      <Routes
        pagePath={pagePath}
        sidePanelToggle={sidePanelToggle}
        teamData={teamData}
        windowWidth={windowWidth}
      />
    </div>
  );
};

export default App;
