import React from "react";
import "./locations.sass";
import BookAppointmentButton from "../appointment-button";
import InsideSalon from "../inside-salon";
import Map from "../map";
import BuildingPicSquared from "../../assets/homePageExterior.jpg";
import AddressCard from "../address-card";

const HeroWithoutButton = ({ styleClass }) => {
  return (
    <div className={`opener ${styleClass}`}>
    </div>
  )
};

const Locations = ({ sidePanelToggle, windowWidth }) => {
  const sidePanelOpen = sidePanelToggle ? "fixed" : "";
  const pagePath = window.location.pathname;

  return (
    <div className={`${sidePanelOpen}`}>
      {windowWidth >= 1024 ? (
        <div>
          {pagePath === "/location" ? (
            // Screen width >= 1024 and location page
            <div>
              <HeroWithoutButton styleClass={"locations-background"} />
              <h2 className="location-title">LOCATION</h2>
              <div className="location">
                <Map resize="map-desktop" />
                <AddressCard />
              </div>
              <BookAppointmentButton color={"blue"} size={"wide"} />
              <InsideSalon />
            </div>
          ) : (
            // Screen width >= 1024 and home page
            <div className="location">
              <div className="store-front">
                <img src={BuildingPicSquared} alt="store front"></img>
              </div>
              <AddressCard />
            </div>
          )}
        </div>
      ) : (
        // Screen width < 1024 and location page
        <div>
          {pagePath === "/location" ? (
            <div>
              <div className="location-page-building-image">
                <img src={BuildingPicSquared} alt="store front"></img>
              </div>
              <div className="location">
                <h3 className="location-title">LOCATION</h3>
                <AddressCard />
                <Map resize="map-mobile" />
              </div>
              <BookAppointmentButton color="blue" size="wide" />
              <InsideSalon />
            </div>
          ) : (
            // Screen width < 1024 and home page
            <div className="location">
              <div className="store-front">
                <img src={BuildingPicSquared} alt="store front"></img>
              </div>
              <AddressCard />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Locations;
