import React from 'react';
import './hero-image.sass';
import BookAppointmentButton from '../appointment-button';

const HeroImage = ({ calledFrom, buttonSize }) => {

  return (
    <div className={`opener ${calledFrom}`}>
      <div className={'hero-text'}>
        <h2>AN EXCLUSIVE NAILBAR EXPERIENCE.</h2>
        <BookAppointmentButton color='white' size={buttonSize}/>
      </div>
    </div>
  )

};

export default HeroImage;
