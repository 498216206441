import React from "react";
import HeroImage from "../hero-image";
import ServicesWide from "../services-wide";
import ServiceCarousel from "../services-carousel";
import PictureCarousel from "../picture-carousel";
import Location from "../locations";
import InteriorPic from "../../assets/home-interior.jpg";
import "./home.sass";

const Home = ({ sidePanelToggle, windowWidth }) => {
  const sidePanelOpen = sidePanelToggle ? "fixed" : "";

  return (
    <div className={`home ${sidePanelOpen}`}>
      <HeroImage calledFrom={"home-background"} buttonSize="wide" />
      <div className="home-image-and-motto">
        <div className="home-motto-image">
          <img src={InteriorPic} alt="interior" />
        </div>
        <p className="home-motto">
          Welcome to Rino Nail Bar, where nail care meets self-expression. Whether that’s bright and bold, flirty and fierce, or polished and proud. We believe healthy, beautiful nails are an essential part of any good self-care routine because when you look good, you feel good – and glamorous nails never go out of style!
        </p>
      </div>
      {windowWidth < 1024 ? (
        <ServiceCarousel colorScheme={"bluebck-whitetxt"} />
      ) : (
        <ServicesWide colorScheme={"bluebck-whitetxt"} />
      )}
      <PictureCarousel windowWidth={windowWidth} />
      <h3 className="location-title">LOCATION</h3>
      <Location windowWidth={windowWidth} />
    </div>
  );
};

export default Home;
