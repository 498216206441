import React from 'react';
import '../inside-salon/inside-salon.sass';
import INTERIOR_PICS from '../../data/interior-pics';
import GalleryModal from '../gallery-modal'

const smallPics = INTERIOR_PICS.smallPics;

const insideSalon = () => {

  return (
    <div className="interior-pics">
      <h3>INSIDE THE SALON</h3>
      <div className="small-pics-container">
        {smallPics.map(pic => {
          return (
            <div className="small-pics">
            <GalleryModal
              key={pic.item}
              url={pic.url}
              alt={pic.alt}
            />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default insideSalon;
