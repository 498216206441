import React from "react";
import "./services-page.sass";
// import PolishDisplay from "../../assets/services-hero.jpg";
import BookAppointmentButton from "../appointment-button";
import Footer from "../footer";
import PictureCarousel from "../picture-carousel";
import ServicesCarousel from "../services-carousel";
import ServicesWide from "../services-wide";

const ServicesPage = ({ sidePanelToggle, windowWidth }) => {
  return (
    <div>
      {windowWidth < 1024 ? (
        <div>
          <div className="polish-display">
          </div>
          <ServicesCarousel
            sidePanelToggle={sidePanelToggle}
            colorScheme={"whitebck-blacktxt"}
          />
          <BookAppointmentButton color={"blue"} size={"narrow"}/>
          <PictureCarousel windowWidth={windowWidth} />
          <Footer />
        </div>
      ) : (
        <div>
          <div className="polish-display">
          </div>
          <ServicesWide
            sidePanelToggle={sidePanelToggle}
            colorScheme={"whitebck-blacktxt"}
          />
          <div className="appointment-button-services">
            <BookAppointmentButton color={"blue"} size={"wide"} />
          </div>
          <PictureCarousel windowWidth={windowWidth} />
          <Footer />
        </div>
      )}
    </div>
  );
};

export default ServicesPage;
