import React from "react";
import Slider from "react-slick";
import "./picture-carousel.sass";

const style = {
  dots: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true
  // centerMode: true,
  // focusOnSelect: true
};

const PictureCarousel = ({ windowWidth }) => {
  style.slidesToShow = windowWidth < 1024 ? 1 : 4;

  return (
    <div className="image-carousel">
      <div className="social-media">
        <p className="instagram-pics-header">RINO ON INSTAGRAM</p>
        <div className="carousel-inner">
          <Slider {...style} className="image-slider">
            <div>
              <img
                className="insta-images"
                src="https://www.instagram.com/p/B0G1HHyJWU-/media"
                alt="RNB Opener"
              />
            </div>
            <div>
              <img
                className="insta-images"
                src="https://www.instagram.com/p/B4TKu1LJrCl/media"
                alt="pink and blue nails"
              />
            </div>
            <div>
              <img
                className="insta-images"
                src="https://www.instagram.com/p/B4Glwq_JPgP/media"
                alt="dark blue nails"
              />
            </div>
            <div>
              <img
                className="insta-images"
                src="https://www.instagram.com/p/B30ZbpwJAXX/media"
                alt="comic look nails"
              />
            </div>
            <div>
              <img
                className="insta-images"
                src="https://www.instagram.com/p/B3N7CBzpoFl/media"
                alt="rino nail bar logo with nail examples"
              />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default PictureCarousel;
