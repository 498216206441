import React from "react";
import ServicesBuild from "../service-build";
import "./services-wide.sass";

const ServiceWide = ({ colorScheme }) => {
  return (
    <div className={`services ${colorScheme}`}>
      <h3 className="title">SERVICES</h3>
      <div className="services-container">
        <div className="column service-column1">
          <ServicesBuild groupNumber={1} />
        </div>
        <div className="column service-column2">
          <ServicesBuild groupNumber={0} />
        </div>
        <div className="column service-column3">
          <ServicesBuild groupNumber={2} />
        </div>
        <div className="column service-column4">
          <ServicesBuild groupNumber={4} />
        </div>
        <div className="column service-column5">
          <ServicesBuild groupNumber={3} />
        </div>
      </div>
      <p className="special-pricing">
        **Children under 12 have a special pricing (ask Technician)
      </p>
    </div>
  );
};

export default ServiceWide;
