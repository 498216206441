import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../home';
import OurTeam from '../our-team';
import Locations from '../locations';
import Book from '../book';
import error from '../error';
import ServicesPage from '../services-page';
import Footer from '../footer';

const Routes = ({ sidePanelToggle, teamData, windowWidth }) => (
  <Switch>
    <Route
      path="/"
      exact
      render={() => (
        <div>
          <Home sidePanelToggle={sidePanelToggle} windowWidth={windowWidth} />
          <Footer />
        </div>
      )}
    />
    <Route
      path="/team"
      render={() => (
        <div>
          <OurTeam sidePanelToggle={sidePanelToggle} teamData={teamData} />
          <Footer />
        </div>
      )}
    />
    <Route
      path="/services"
      render={() => (
        <ServicesPage
          sidePanelToggle={sidePanelToggle}
          windowWidth={windowWidth}
        />
      )}
    />
    <Route
      path="/location"
      render={() => (
        <div>
          <Locations
            sidePanelToggle={sidePanelToggle}
            windowWidth={windowWidth}
          />
          <Footer />
        </div>
      )}
    />
    <Route path="/book" render={() => <Book />} />
    <Route path="" component={error} />
  </Switch>
);

export default Routes;
