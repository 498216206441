import React from 'react';
import { withRouter } from 'react-router';
import RinoLogo from '../../assets/Rino-logo-111019.svg';
import TopNavPanel from '../top-nav-panel';
import './nav-bar.sass';

const NavBar = props => {
  const activeNavButton = props.sidePanelToggle ? 'selected' : '';
  const noSidePanel = !props.sidePanelToggle ? 'return' : '';
  const homePath = props.location.pathname === '/' ? 'home-path' : '';
  const windowWidth = props.windowWidth;

  return (
    <div className={`nav-bar ${activeNavButton} ${homePath}`}>
      <a href="/">
        <img
          className={`main-logo ${activeNavButton}`}
          src={RinoLogo}
          alt="logo"
        />
      </a>
      {windowWidth < 1024 ? (
        <div className="hamburger-menu">
          <div
            className="toggle-hamburger-menu"
            onClick={props.toggleSidePanel}
          >
            <span
              className={`line ${activeNavButton} ${noSidePanel} one`}
            ></span>
            <span
              className={`line ${activeNavButton} ${noSidePanel} two`}
            ></span>
            <span
              className={`line ${activeNavButton} ${noSidePanel} three`}
            ></span>
          </div>
        </div>
      ) : (
        <TopNavPanel />
      )}
    </div>
  );
};

export default withRouter(NavBar);
