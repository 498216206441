import React from 'react';
import './appointment-button.sass';

const BookAppointmentButton = ({ color, size }) => (
  <a href="https://www.facebook.com/foryourmani/">
    <button className={`book-appointment-button ${color} ${size}`}>
      BOOK APPOINTMENT
    </button>
  </a>
);

export default BookAppointmentButton;
