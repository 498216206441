import React from 'react';
import Modal from '@material-ui/core/Modal';

export default function GalleryModal({ url, alt }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="small-pics">
        <img src={url} alt={alt} onClick={handleOpen} ></img>
      </div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div className="large-pic">
          <img src={url} alt={alt} ></img>
        </div>
      </Modal>
    </div>
  );
}

